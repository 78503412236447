import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p">{`*`}{`This Saturday, February 4th, we will have our 3rd Annual Sports
Nutrition seminar at Louisville East with Dawn from Sports Nutrition 2
Go in Cincinnati. The seminar will be from 12-2pm. \\$35/person for the
seminar.`}</strong></p>
    <p><strong parentName="p">{`Subjects covered will include:`}</strong></p>
    <p><strong parentName="p">{`Hydration—How impacts performance, electrolytes, what to drink`}</strong></p>
    <p><strong parentName="p">{`Breakfast—effects afternoon performance by 5% and effects appetite`}</strong></p>
    <p><strong parentName="p">{`Nutrient Timing—what to eat before/after, when`}</strong></p>
    <p><strong parentName="p">{`Sleep and Nutrition on performance---Discuss percentages on outcomes`}</strong></p>
    <p><strong parentName="p">{`Discuss different nutrition philosophies`}</strong></p>
    <p><strong parentName="p">{`Supplements and Labs: what to take and which labs to draw`}</strong></p>
    <p><strong parentName="p">{`Also: She will cover what ever the audience would like! If you would
like to attend and you want to forward questions to her ahead of time
please send them to: Daniel\\@crossfitlouisvilleeast.com.`}</strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`Want to learn the exact number of calories your body burns each
day? Metabolic Testing will be offered before and after the seminar as
well! \\$65 for Metabolic Testing. Please email Daniel if you’d like to
sign up for Metabolic testing either before or after the seminar. There
is a sign up sheet at The Ville by the front door and at East on the
back wall. More time slots can be added earlier in the morning if needed
but slots are filling up fast so sign up today.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      